<template>
  <div>
    <div style="height: 1500px" class="common_liucheng">
      <div
        style="
          padding-top: 30px;
          height: 530px;
          width: 95%;
          margin-left: 50%;
          transform: translateX(-50%);
          border-bottom: 2px dotted rgb(24,144,255);
        "
      >
        <span
          style="
            display: block;
            font-size: 18px;
            font-weight: bold;
            margin-bottom: 30px;
          "
          >基础信息</span
        >
        <div
          style="
            height: 360px;
            width: 100%;
            border: 1px solid rgba(24,144,255, 0.5);
            border-right: none !important;
          "
        >
          <div style="height: 55px; line-height: 55px; font-size: 15px">
            <Row>
              <Col span="4">
                <div class="table-box">企业名称</div>
              </Col>
              <Col span="8">
                <div class="table-box-double" style="overflow: hidden;text-overflow: ellipsis;white-space: nowrap" :title="totalData.enterpriseName">
                  {{ totalData.enterpriseName }}
                </div>
              </Col>
              <Col span="4">
                <div class="table-box" style="overflow: hidden;text-overflow: ellipsis;white-space: nowrap">企业类型</div>
              </Col>
              <Col span="8">
                <div class="table-box-double" style="overflow: hidden;text-overflow: ellipsis;white-space: nowrap" :title="totalData.enterpriseType">
                  {{ totalData.enterpriseType}}
                </div>
              </Col>
            </Row>
          </div>
          <div style="height: 55px; line-height: 55px; font-size: 15px">
            <Row>
              <Col span="4">
                <div class="table-box">法定代表人</div>
              </Col>
              <Col span="8">
                <div class="table-box-double yi_hang_sheng">
                  <span :title="totalData.legalRepresentative">{{ totalData.legalRepresentative }}</span>
                </div>
              </Col>
              <Col span="4">
                <div class="table-box">所属行业</div>
              </Col>
              <Col span="8">
                <div class="table-box-double yi_hang_sheng">
                  <span :title="totalData.involved">{{ totalData.involved }}</span>
                </div>
              </Col>
            </Row>
          </div>
          <div style="height: 55px; line-height: 55px; font-size: 15px">
            <Row>
              <Col span="4">
                <div class="table-box">行政区划</div>
              </Col>
              <Col span="8">
                <div class="table-box-double yi_hang_sheng" >
                  <span :title="totalData.administrativeDivision"> {{ totalData.administrativeDivision }}</span>
                </div>
              </Col>
              <Col span="4">
                <div class="table-box">营业期限</div>
              </Col>
              <Col span="8">
                <div class="table-box-double yi_hang_sheng">
                  <span :title="totalData.businessTerm"> {{ totalData.businessTerm }}</span>
                </div>
              </Col>
            </Row>
          </div>
          <div style="height: 55px; line-height: 55px; font-size: 15px">
            <Row>
              <Col span="4">
                <div class="table-box">企业地址</div>
              </Col>
              <Col span="8">
                <div class="table-box-double yi_hang_sheng" style="text-overflow: ellipsis;white-space: nowrap">
                  <span :title="totalData.site">{{ totalData.site }}</span>
                </div>
              </Col>
              <Col span="4">
                <div class="table-box">员工数量</div>
              </Col>
              <Col span="8">
                <div class="table-box-double">
                  <span>{{ totalData.staffTotal }}</span>
                </div>
              </Col>
            </Row>
          </div>
          <div style="height: 55px; line-height: 55px; font-size: 15px">
            <Row>
              <Col span="4">
                <div class="table-box">注册资本金</div>
              </Col>
              <Col span="8">
                <div class="table-box-double yi_hang_sheng">
                  <span>{{ totalData.registeredCapital}}</span>
                </div>
              </Col>
              <Col span="4">
                <div class="table-box">所属科技领域</div>
              </Col>
              <Col span="8">
                <div class="table-box-double yi_hang_sheng" style="text-overflow: ellipsis;white-space: nowrap">
                  <span>{{ totalData.satTerritory }}</span>
                </div>
              </Col>
            </Row>
          </div>
          <div style="height: 85px; line-height: 85px; font-size: 15px">
            <Row>
              <Col span="4">
                <div class="table-box" style="border-bottom: none">
                  经营范围
                </div>
              </Col>
              <Col span="20">
                <div class="table-box-double" style="border-bottom: none;padding: 10px;display: flex;align-items: center;">
                  <span :title="totalData.businessScope" style="line-height: 20px;  overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    width: 100%;
    -webkit-box-orient: vertical;">{{ totalData.businessScope }}</span>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </div>
      <div
        style="
          padding-top: 30px;
          height: 380px;
          width: 95%;
          margin-left: 50%;
          transform: translateX(-50%);
          border-bottom: 2px dotted rgb(24,144,255);
        "
      >
        <span
          style="
            display: block;
            font-size: 18px;
            font-weight: bold;
            margin-bottom: 30px;
          "
          >产品信息</span
        >
        <div
          style="
            height: 195px;
            width: 100%;
            border: 1px solid rgba(24,144,255, 0.5);
            border-right: none !important;
          "
        >
          <div style="height: 55px; line-height: 55px; font-size: 15px">
            <Row>
              <Col span="4">
                <div class="table-box2">主营产品名称</div>
              </Col>
              <Col span="8">
                <div class="table-box-double yi_hang_sheng">
                  <span :title="totalData.productName">{{ totalData.productName }}</span>
                </div>
              </Col>
              <Col span="4">
                <div class="table-box2">主营产品标签</div>
              </Col>
              <Col span="8">
                <div class="table-box-double yi_hang_sheng">
                  <span :title="totalData.productLabel">{{ totalData.productLabel }}</span>
                </div>
              </Col>
            </Row>
          </div>
          <div style="height: 55px; line-height: 55px; font-size: 15px">
            <Row>
              <Col span="4">
                <div class="table-box2 yi_hang_sheng">主营产品技术领域</div>
              </Col>
              <Col span="8">
                <div class="table-box-double yi_hang_sheng">
                  <span :title="totalData.mptName">{{ totalData.mptName }}</span>
                </div>
              </Col>
              <Col span="4">
                <div class="table-box2">产品获奖情况</div>
              </Col>
              <Col span="8">
                <div class="table-box-double yi_hang_sheng">
                  <span :title="totalData.prizeWinning">{{ totalData.prizeWinning }}</span>
                </div>
              </Col>
            </Row>
          </div>
          <div style="height: 85px; line-height: 85px; font-size: 15px">
            <Row>
              <Col span="4">
                <div class="table-box2" style="border-bottom: none">
                  主营产品描述
                </div>
              </Col>
              <Col span="20">
                <div class="table-box-double" style="border-bottom: none;display: flex;align-items: center">
                  <span :title="totalData.productDescribe" style="line-height: 20px;  overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    width: 100%;
    -webkit-box-orient: vertical;">{{ totalData.productDescribe }}</span>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </div>
      <div
        style="
          padding-top: 30px;
          height: auto;
          padding-bottom: 100px;
          width: 95%;
          margin-left: 50%;
          transform: translateX(-50%);
          border-bottom: 2px dotted rgb(24,144,255);
        "
      >
        <span
          style="
            display: block;
            font-size: 18px;
            font-weight: bold;
            margin-bottom: 30px;
          "
          >财务信息</span
        >
        <div
          style="
            height: auto;
            width: 100%;
            border: 1px solid rgba(24,144,255, 0.5);
            border-right: none !important;
            border-bottom: none !important;
          "
        >
          <div style="font-size: 15px">

            <Row style="height: 55px; line-height: 55px;" v-for="i in 3" :key="i">
              <Col span="4">
                <div class="table-box">{{new Date().getFullYear()- i-1}}年度销售收入</div>
              </Col>
              <Col span="4">
                <div class="table-box-double">--</div>
              </Col>
              <Col span="4">
                <div class="table-box">年度净资产总额</div>
              </Col>
              <Col span="4">
                <div class="table-box-double">--</div>
              </Col>
              <Col span="4">
                <div class="table-box">年度研发投入总额</div>
              </Col>
              <Col span="4">
                <div class="table-box-double">--</div>
              </Col>
            </Row>
          </div>
        </div>
      </div>
      <div
        style="
          padding-top: 30px;
          height: 200px;
          width: 95%;
          margin-left: 50%;
          transform: translateX(-50%);
        "
      >
        <span
          style="
            display: block;
            font-size: 18px;
            font-weight: bold;
            margin-bottom: 30px;
          "
          >知识产权信息</span
        >
        <div
          style="
            height: auto;
            width: 100%;
            border: 1px solid rgba(24,144,255, 0.5);
            border-right: none !important;
            border-bottom: none !important;
          "
        >
          <div style="height: 55px; line-height: 55px; font-size: 15px">
            <Row>
              <Col span="3">
                <div class="table-box">企业专利总数</div>
              </Col>
              <Col span="3">
                <div class="table-box-double">
                  <span>{{ totalData.patentTotal }}</span>
                </div>
              </Col>
              <Col span="3">
                <div class="table-box">发明类专利</div>
              </Col>
              <Col span="3">
                <div class="table-box-double">
                  <span>{{ totalData.inventionPatents }}</span>
                </div>
              </Col>
              <Col span="3">
                <div class="table-box">实用型专利</div>
              </Col>
              <Col span="3">
                <div class="table-box-double">
                  <span>{{ totalData.appliedPatents }}</span>
                </div>
              </Col>
              <Col span="3">
                <div class="table-box">外观设计专利</div>
              </Col>
              <Col span="3">
                <div class="table-box-double">
                  <span>{{ totalData.designPatents }}</span>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getEnterpriseInfo } from "../../../plugins/api/EnterpriseManagementCenter";
import {mapGetters} from 'vuex'
import {errorToast} from "../../../plugins/tools/util";
export default {
  name: "EnterPriseInfo",
  data() {
    return {
      totalData: {
        enterpriseName: "",
        enterpriseType: "",
        designPatents: "",
        appliedPatents: "",
        inventionPatents: "",
        patentTotal: "",
        productDescribe: "",
      },
    };
  },
  computed: {
    ...mapGetters({
      token: "userStore/token",
      userName: "userStore/userName",
      userId: "userStore/userId",
      mbrMemberId: "userStore/mbrMemberId",
    }),
  },
  mounted() {
    this.getInfo();
  },
  methods: {
    async getInfo() {
      let params = {
        mbrMemberId:this.mbrMemberId,
      };
      const json = await getEnterpriseInfo(params);
      if(json.code == 0){
        this.totalData = json.result;
        for(let item in this.totalData) {
          if(this.totalData[item] == null){
            this.totalData[item] = '--'
          }
        }
        // this.$parent.changeHeight();
      }else{
        errorToast(json.message)
      }

    },
  },
};
</script>

<style scoped lang="scss">
/deep/ .ivu-table-tbody tr:nth-child(2n) td {
  background-color: #f3fcfc !important;
  color: black !important;
}

.yi_hang_sheng {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.div_center {
  width: 95%;
  margin-left: 50%;
  transform: translateX(-50%);
  border-bottom: 2px dotted rgb(218, 226, 229);
}

.table-box {
  width: 100%;
  height: 100%;
  background-color: rgba(243, 252, 252, 0.4);

  text-align: center;
  border-bottom: 1px solid rgba(24,144,255, 0.5);
  border-right: 1px solid rgba(24,144,255, 0.5);
}

.table-box-double {
  padding: 0 10px;
  overflow: hidden;

}
.neirong {
  margin-left: 5px;

  .nei_rong_box {
    height: 620px;
    width: 95%;
    margin-left: 50%;
    transform: translateX(-50%);
    border-bottom: 2px dotted #f5f5f5;
  }

  p {
    display: inline-block;
    margin-bottom: 20px;
    font-size: 14px;
    color: black;
  }
}
.yi_hang_sheng {
  text-overflow: ellipsis;
  white-space: nowrap;
}
.table-box2 {
  width: 100%;
  height: 100%;
  background-color: rgba(243, 252, 252, 0.4);

  text-align: center;
  border-bottom: 1px solid rgba(24,144,255, 0.5);
  border-right: 1px solid rgba(24,144,255, 0.5);
}

.table-box-double {
  width: 100%;
  height: 100%;

  text-align: center;
  border-bottom: 1px solid rgba(24,144,255, 0.5);
  border-right: 1px solid rgba(24,144,255, 0.5);
}
</style>
